body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

iframe a {
  pointer-events: none;
}

.p-right {
  padding-right: 4px;
}

.p-left {
  padding-left: 4px;
}

.MuiDataGrid-footerContainer {
  justify-content: start !important;
}

.root {
  height: calc(
    var(--vh, 1vh) * 100
  ); /* Use vh as a fallback for browsers that do not support Custom Properties */
  position: relative;
  display: flex;
}
.musician .MuiInput-underline:before {
  border-bottom: 0px solid rgba(0, 0, 0, 0) !important;
}
.musician .MuiInput-underline:after {
  border-bottom: 0px solid rgba(0, 0, 0, 0) !important;
}

.success {
  background: #4caf5022;
}

.warning {
  background: #ffc10722;
}

.error {
  background: #f4433622;
}

.dialog-paper {
  overflow: inherit !important;
}

.lock {
  background: rgba(155, 155, 155, 0.08);
  color: rgba(155, 155, 155);
  cursor: not-allowed;
}

.opacity {
  opacity: 0.35;
  background: rgba(155, 155, 155, 0.08);
}

.page {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  position: absolute;
  overflow: auto;
  top: 0;
  bottom: 0;
  left: 0;
}

.container {
  overflow: auto;
  position: relative;
  min-height: min-content;
  flex-grow: 1;
  display: flex;
}

.padding {
  padding: 20px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.noPadding {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.cardHeader {
  padding: 25px;
  position: absolute;
  margin-top: -50px;
  margin-left: -5px;
}

.card {
  padding: 20px;
  margin-top: 40px;
  position: relative;
}

.dotted {
  background-image: -webkit-repeating-radial-gradient(
    1px 1px,
    rgba(255, 255, 255, 0.25),
    rgba(255, 255, 255, 0.25) 1px,
    transparent 1px,
    transparent 100%
  );
  background-image: -moz-repeating-radial-gradient(
    1px 1px,
    rgba(255, 255, 255, 0.25),
    rgba(255, 255, 255, 0.25) 1px,
    transparent 1px,
    transparent 100%
  );
  background-image: -ms-repeating-radial-gradient(
    1px 1px,
    rgba(255, 255, 255, 0.25),
    rgba(255, 255, 255, 0.25) 1px,
    transparent 1px,
    transparent 100%
  );
  background-image: repeating-radial-gradient(
    top left,
    rgba(255, 255, 255, 0.25),
    rgba(255, 255, 255, 0.25) 1px,
    transparent 1px,
    transparent 100%
  );
  -webkit-background-size: 20px 20px;
  -moz-background-size: 20px 20px;
  background-size: 20px 20px;
}

.refID {
  background: white;
  font-size: 10px;
  padding: 2px;
  color: #001e3c;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.absolute {
  position: absolute;
  top: 16px;
  right: -1px;
}

.lined {
  border: solid 1px rgba(155, 155, 155, 0.3);
}

.MuiBackdrop-root {
  backdrop-filter: blur(5px);
  --webkit-backdrop-filter: blur(20px);
  background: rgba(0, 30, 60, 0.9) !important;
}

.pinkBorder {
  border: solid 1px rgba(233, 30, 99, 0.3);
  background: rgba(233, 30, 99, 0.1);
  color: #e91e63;
  padding: 2px 4px;
  border-radius: 4px;
}

.inputBase {
  font-size: 0.875rem;
  background: rgba(155, 155, 155, 0.1);
  padding: 2px;
  border: solid 1px rgba(155, 155, 155, 0.3);
  border-radius: 4px;
}

.inputBase:hover {
  background: rgba(33, 150, 243, 0.1);
  border: solid 1px rgba(33, 150, 243, 0.3);
}

.projectHiringSelected {
  background: rgba(3, 169, 244, 0.1) !important;
}

.projectHiringDoubleSelected {
  background: rgba(3, 169, 244, 0.2);
}

.projectHiringSectionRow {
  background: rgba(155, 155, 155, 0.2);
}

.cellReadOnly {
  background: rgba(155, 155, 155, 0.08);
  color: rgba(155, 155, 155);
  cursor: not-allowed;
}

.cellSuccess {
  background: #4caf4f27;
  color: #4caf50;
}

.cellDanger {
  background: #f4433628;
  color: #f44336;
}

.cellInfo {
  background: #2196f328;
  color: #2196f3;
}

.cellWarning {
  background: #ff990029;
  color: #ff9800;
}

.selectedField {
  border: solid 2px #2196f3;
  background: white;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background: white !important;
}

.key {
  padding: 5px;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1px;
  font: 11px Consolas, Liberation Mono, Menlo, monospace;
  line-height: 10px;
  color: #1a2027;
  vertical-align: middle;
  background-color: #fafafa;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: inset 0 -1px 0 #e0e0e0;
}

/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
} */

.canvas {
  background-color: rgba(240, 247, 255, 0.5);
  background-clip: padding-box;
  background-image: radial-gradient(
      at 51% 52%,
      rgba(240, 247, 255, 0.5) 0px,
      transparent 50%
    ),
    radial-gradient(at 80% 0%, rgb(255, 255, 255) 0px, transparent 20%),
    radial-gradient(at 0% 95%, rgba(194, 224, 255, 0.3), transparent 40%),
    radial-gradient(at 0% 20%, rgb(240, 247, 255) 0px, transparent 50%),
    radial-gradient(at 93% 85%, rgba(194, 224, 255, 0.2) 0px, transparent 50%),
    url("https://storage.googleapis.com/rhapsody/canvas.svg");
}

.turnip {
  font-family: turnip, serif !important;
}

.sectionRow {
  background: rgba(155, 155, 155, 0.2);
}

.archived {
  opacity: 0.5;
}

.selecto {
}

.selectoSelected {
  background: #2195f321 !important;
}

.missionControlEdit .selecto2Selected {
  background: #2196f321 !important;
  border-color: #2196f3bb;
  /* border-width: 2px;
  transition: border-width 0.2s; */
  box-shadow: 0px 2px 8px #90979e77;
}

.missionControlInvite .selecto2Selected {
  background: #2196f321 !important;
  border-color: #2196f3bb;
}

.MuiDataGrid-columnHeaderTitleContainerContent {
  flex: 1;
}

.grayscale {
  filter: grayscale(1);
  margin-top: 32px;
}

.grayscale:hover {
  filter: grayscale(0);
}

.shadow {
  box-shadow: 0 6px 28px 0 rgb(24 52 117 / 20%) !important;
}

.shadowHover {
  box-shadow: 0 0px 0px 0 rgb(24 52 117 / 20%) !important;
  transition: box-shadow 1s;
}

.shadowHover:hover {
  box-shadow: 0 6px 28px 0 rgb(24 52 117 / 20%) !important;
  transition: box-shadow 1s;
}

.colored {
  padding: 16px;
  background: white;
  border-radius: 8px;
  border: solid 1px rgba(155, 155, 155, 0.3);
}

.primary {
  color: #ffc107;
}

.featureCard {
  height: 400px;
  background: #f9fafc;
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  color: #cfd8dc;
  border: solid 1px rgba(155, 155, 155, 0.1);
}

.pricingItem {
  padding: 20px 16px 16px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricingHeader {
  padding: 20px 16px 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.amber {
  color: #ffa000;
}

.pricingChip {
  color: black !important;
  background-color: #f5f5f5 !important;
  margin-left: 4px !important;
  font-size: 12px !important;
}

.featured {
  background: #f9fafc;
  border-left: solid 1px #e6e8ec;
  border-right: solid 1px #e6e8ec;
  transition: background 0.3s;
}

.selected {
  background: rgba(255, 193, 7, 0.4) !important;
  border-left: solid 1px #ffa000;
  border-right: solid 1px #ffa000;
  color: white !important;
  transition: background 0.3s;
}

.selectable {
  transition: background 0.3s;
  cursor: pointer;
  border-left: dashed 2px rgba(255, 193, 7, 0.5);
  border-right: dashed 2px rgba(255, 193, 7, 0.5);
  background: rgba(255, 193, 7, 0.03);
}

.selectable:hover {
  background: #fff8e1;
  border-left: solid 2px rgba(255, 193, 7, 0.5);
  border-right: solid 2px rgba(255, 193, 7, 0.5);
  transition: background 0.3s;
  cursor: pointer;
}

.pricingContainer {
  margin: auto;
  flex: 1;
  padding: 64px 16px;
  position: relative;
}

.recommanded {
  color: #ffa000;
  background: #f9fafc;
  border: solid 1px #ffa000;
  display: inline-block;
  border-radius: 16px;
  padding: 2px 6px;
  position: absolute;
  top: -12px;
  left: 18px;
}

.pricingRecommandedHeader {
  padding: 20px 16px 16px 16px;
  background: #f9fafc;
  border-top: solid 1px #e6e8ec;
  border-left: solid 1px #e6e8ec;
  border-right: solid 1px #e6e8ec;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
}

.pricingTopHeader {
  padding: 20px 16px 16px 16px;
  border-top: solid 1px white;
  border-left: solid 1px white;
  border-right: solid 1px white;
  background: white;
  position: relative;
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
}

.pricingSubHeader {
  background: #f4f6f9;
  padding: 8px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.green {
  color: #4caf50;
}

.DatGridBottomPadding .MuiDataGrid-virtualScroller {
  padding-bottom: 120px;
}

.jiggle:nth-child(2n) {
  animation-name: keyframes1;
  animation-iteration-count: infinite;
  transform-origin: 50% 10%;
}

.jiggle:nth-child(2n-1) {
  animation-name: keyframes2;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transform-origin: 30% 5%;
}

@keyframes keyframes1 {
  0% {
    transform: rotate(-1deg);
    animation-timing-function: ease-in;
  }

  50% {
    transform: rotate(1.5deg);
    animation-timing-function: ease-out;
  }
}

@keyframes keyframes2 {
  0% {
    transform: rotate(1deg);
    animation-timing-function: ease-in;
  }

  50% {
    transform: rotate(-1.5deg);
    animation-timing-function: ease-out;
  }
}

#floating-text {
  position: absolute;
  pointer-events: none;
  user-select: none;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  transform: translate(-50%, -50%);
}

.emptyChair {
  border-radius: 8px;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.row-selected {
  background: #2196f322;
}

.cell-issue {
  background: #f4433622;
  color: #f44336;
}

.tour-empty {
  /* background: red; */
}

.no-signal {
  background-image: url("./no-signal.gif");
  opacity: 0.15;
  height: 100vh;
  width: 100vw;
  position: absolute;
}

.frame {
  height: 100vh;
  width: 10vw;
  position: absolute;
}

canvas {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.caps {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: as 8s linear infinite;
}

.caps img {
  display: block;
  width: 100%;
  height: 100%;
}

@keyframes as {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.3;
  }
  20% {
    opacity: 0.1;
  }
  30% {
    opacity: 0.5;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  55% {
    opacity: 0;
  }
  55% {
    opacity: 0;
  }
}

.frame {
  z-index: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(88, 52, 52, 0) 0%,
    rgba(155, 155, 155, 0.1) 50%,
    rgba(0, 0, 0, 0.9) 100%
  ); /* FF3.6+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = '#00000000', endColorstr = '#e6000000', GradientType = 1); /* IE6-9 fallback on horizontal gradient */
}

.frame div {
  position: absolute;
  left: 0;
  top: -20%;
  width: 100%;
  height: 20%;
  background-color: rgba(155, 155, 155, 0.1);
  filter: blur(8px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  animation: asd 12s linear infinite;
}

.frame div:nth-child(1) {
  animation-delay: 0;
}

.frame div:nth-child(2) {
  animation-delay: 4s;
}

.frame div:nth-child(3) {
  animation-delay: 8s;
}

.vjs_video_3-dimensions {
  width: 100% !important;
}

@keyframes asd {
  0% {
    top: -20%;
  }
  100% {
    top: 100%;
  }
}

@keyframes asdd {
  0% {
    text-shadow: 0 0 0px rgba(255, 255, 255, 0.8);
  }
  33% {
    text-shadow: 0 0 2px rgba(255, 255, 255, 0.8);
  }
  66% {
    text-shadow: 0 0 3px rgba(255, 255, 255, 0.84);
  }
  100% {
    text-shadow: 0 0 0px rgba(255, 255, 255, 0.7);
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  #printableDiv,
  #printableDiv * {
    visibility: visible;
  }
  #printableDiv {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}
